<script setup lang="ts">
import PrimeAvatar, { type AvatarProps } from 'primevue/avatar'

interface Props extends AvatarProps {
  email?: string
  firstName?: string
  lastName?: string
  subAccount?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  shape: 'circle',
  size: 'large'
})

const finalSize = computed<Props['size']>(() => {
  if (props.subAccount) {
    return 'normal'
  }
  return props.size
})

const label = computed(() => {
  if (props.firstName) {
    return `${props.firstName[0]}${props.lastName ? props.lastName[0] : ''}`.toUpperCase()
  }
  if (props.email) {
    return props.email[0].toUpperCase()
  }
  return ''
})

const imgSrc = ref('')

watch(() => props.email, async email => {
  if (email) {
    const img = new Image()
    /** @see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest  */
    const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(email))
    const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
    const hash = hashArray
      .map(b => b.toString(16).padStart(2, '0'))
      .join('') // convert bytes to hex string
    img.crossOrigin = 'Anonymous'
    img.src = `https://www.gravatar.com/avatar/${hash}?d=blank&s=84`
    img.onload = () => {
      /** Determine if we were given the fallback image, meaning the user doesn't have one */
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      if (!ctx) {
        return
      }

      // We're just going to draw one pixel
      canvas.width = 1
      canvas.height = 1

      // Draw only the top-left pixel of the image
      ctx.drawImage(img, 0, 0, 1, 1, 0, 0, 1, 1)

      // Get the top-left pixel data (RGBA)
      const pixelData = ctx.getImageData(0, 0, 1, 1).data

      // Check if the alpha value (4th value) is 0 (fully transparent)
      if (pixelData[3] !== 0) {
        imgSrc.value = img.src
      }
    }
  }
}, { immediate: true })

</script>

<template>
  <PrimeAvatar
    v-bind="$props"
    :size="finalSize"
    :label
    :pt="{
      label: {
        style: {
          fontSize: finalSize === 'large' ? '1rem' : '0.95rem'
        }
      }
    }"
    class="avatar"
  >
    <img
      v-if="imgSrc"
      :src="imgSrc"
      class="user-avatar"
    />
    <slot v-else />
  </PrimeAvatar>
</template>

<style scoped>
.user-avatar {
  height: auto;
}
.avatar {
  flex-shrink: 0;
}
</style>
